<template>
  <!-- <van-nav-bar title="学生" left-arrow @click-left="onClickLeft"></van-nav-bar> -->
  <div class="student" style="margin: 10px 15px 10px">
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text=""
      @load="onLoad"
    >
      <div class="container" v-for="item in list" :key="item">
        <div class="student_info">
          <div class="info">
            <img src="../../assets/default_profile.png" />
            <div class="student_desc">
              <span>{{ item.name }}</span>
              <span>{{ className }}</span>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { Toast } from "vant";
import { useRouter, useRoute } from "vue-router";
import { safeName } from "../../common/js/utils";
import { setStorage, getStorage } from "../../common/js/storeUtil";
import api from "../../service/api";

export default {
  setup() {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const router = useRouter(); //路由传值
    const Route = useRoute();
    const className = ref("");

    onMounted(() => {
      /* eslint-disable */
      //setTitle("学生列表");
      className.value = Route.query.className;
    });

    // let store = useStore();
    const onClickLeft = () => {
      router.go(-1);
    };

    const onLoad = () => {
      api
        .studentList({
          class_id: Route.query.classId,
        })
        .then((res) => {
          if (res.data.code == 1001) {
            var students = res.data.result;
            for (let i = 0; i < students.length; i++) {
              list.value.push(students[i]);
            }
          } else {
            Toast(res.data.message);
          }
        })
        .catch((err) => {
          Toast(err.message);
          // console.log("请求->", err.m);
        });

      // 加载状态结束
      loading.value = false;
      // 数据全部加载完成
      finished.value = true;
    };

  

    return {
      list,
      onLoad,
      loading,
      finished,
      onClickLeft,
      safeName,
      className,
    };
  },
};
</script>

<style lang="less" scoped>
.student {
  min-height: 500px;
  .container {
    height: 70px;
    .student_info {
      margin: 0 auto;
      height: 60px;
      background: linear-gradient(to right, #3794fc, #82BAFB);
      // background-color: #3794fc;
      box-shadow: 0 1px 4px #3794fc;
      border-radius: 6px;

      .info {
        display: block;
        height: 60px;
        margin: 0 auto;
        .changeBtn {
          float: right;
          margin-top: 10px;
          margin-right: 10px;
        }

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-top: 4px;
          display: inline-block;
          overflow: hidden;
          margin-top: 15px;
          margin-left: 15px;
        }

        .student_desc {
          display: inline-block;
          margin-left: 10px;
          line-height: 20px;
          font-size: 14px;
          color: #fff;
          span {
            display: block;
            color: #fff;
            font-size: 14px;
            padding: 2px 0;
            text-align: left;
          }
          span:first-child {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
